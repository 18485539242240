import { DOWNLOAD_ACTION_CATEGORY } from '../constants';
import { trackingEvent, updateDataLayer } from '../utils';

export default class TrackingEvent {
  init() {
    this.trackingEventGeneralClick();
    this.trackingEventGeneralClickChildren();
    this.trackingEventGeneralClickLinkChildren();

    let count = 0;
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window._satellite?.track !== 'function' || !window.OnetrustActiveGroups) {
      const timer = setInterval(() => {
        // eslint-disable-next-line no-underscore-dangle
        if (typeof window._satellite?.track === 'function' && window.OnetrustActiveGroups) {
          clearInterval(timer);

          if (Array.isArray(window.trackingQueues)) {
            window.trackingQueues.forEach((queue) => {
              const { event, data } = queue;
              updateDataLayer(JSON.parse(data));
              // eslint-disable-next-line no-underscore-dangle
              window._satellite.track(event);
            });

            window.trackingQueues = [];
          }
        }

        count += 1;

        if (count > 40) {
          clearInterval(timer);
        }
      }, 100);
    }
  }

  extractEventAttributes($target) {
    return {
      eventTrigger: $target.attr('data-event-trigger') || '',
      eventType: $target.attr('data-event-type') || '',
      eventLabel: $target.attr('data-event-label') || $target.text().trim(),
      eventCategory: $target.attr('data-event-category') || '',
      eventModule: $target.attr('data-event-module') || '',
      eventPage: $target.attr('data-event-page') || window.pageName,
      href: $target.attr('href') || '',
      isForce: $target.attr('data-force-tracking') === 'true',
      destination: $target.attr('data-event-destination') || '',
      eventTriggerChildren: $target.attr('data-event-trigger-children') || '',
      eventTriggerElement: $target.attr('data-event-trigger-element') || '',
      eventLabelType: $target.attr('data-event-label-type') || '',
      eventLabelGet: $target.attr('data-event-label-get') || '',
      eventTriggerChildrenLink: $target.attr('data-event-trigger-children-link') || '',
      downloadTitle: $target.attr('data-event-download-title') || '',
    };
  }

  trackingEventGeneralClick() {
    window.addEventListener('tracking-event-click', (event) => {
      const {target} = event.detail;
      const $target = $(target);
      const eventAttributes = this.extractEventAttributes($target);

      if (eventAttributes.eventTrigger) {
        trackingEvent({
          event: eventAttributes.eventTrigger,
          type: eventAttributes.eventType,
          label: `${eventAttributes.eventPage}:${eventAttributes.eventModule}:${eventAttributes.eventLabel}`,
          category: eventAttributes.eventCategory,
          custom: {
            // eslint-disable-next-line camelcase
            link_metadata: {
              // eslint-disable-next-line camelcase
              destination_url: eventAttributes.destination,
            },
            metadata: {
              // eslint-disable-next-line camelcase
              target_url: eventAttributes.href,
            },
            // eslint-disable-next-line camelcase
            event_metadata: {
              // eslint-disable-next-line camelcase
              download_title: eventAttributes.downloadTitle,
            },
          },
          isForce: eventAttributes.isForce,
        });
      }
    });
  }

  trackingEventGeneralClickChildren() {
    const $parents = $('[data-event-trigger-children]');
    $parents.each((_i, el) => {
      const {
        eventTriggerChildren: eventTrigger,
        eventType,
        eventCategory,
        eventModule,
        eventTriggerElement,
        eventLabelType,
        eventLabelGet,
      } = this.extractEventAttributes($(el));

      $(el).on('click', eventTriggerElement, (e) => {
        const $target = $(e.currentTarget);
        if (eventTrigger && !$target.attr('data-event-trigger')) {
          let eventLabel = $target.text().trim();
          if (eventLabelType === 'attribute' && eventLabelGet) {
            eventLabel = $target.attr(eventLabelGet);
          }
          const href = $target.attr('href') || '';
          trackingEvent({
            event: eventTrigger,
            type: eventType,
            label: `${window.pageName}:${eventModule}:${eventLabel}`,
            category: eventCategory,
            custom: {
              // eslint-disable-next-line camelcase
              link_metadata: {
                // eslint-disable-next-line camelcase
                destination_url: '',
              },
              metadata: {
                // eslint-disable-next-line camelcase
                target_url: href,
              },
              // eslint-disable-next-line camelcase
              event_metadata: {
                // eslint-disable-next-line camelcase
                download_title: eventCategory === DOWNLOAD_ACTION_CATEGORY ? eventLabel : '',
              },
            },
          });
        }
      });
    });
  }

  trackingEventGeneralClickLinkChildren() {
    $('body').on('click', '[data-event-trigger-children-link] a', (e) => {
      const $target = $(e.currentTarget);
      const $parent = $target.closest('[data-event-trigger-children-link]');

      const {
        eventTriggerChildrenLink: eventTrigger,
        eventType,
        eventCategory,
        eventModule,
        eventPage,
      } = this.extractEventAttributes($parent);

      const {
        href,
        destination,
      } = this.extractEventAttributes($target);

      trackingEvent({
        event: eventTrigger,
        type: eventType,
        label: `${eventPage}:${eventModule}:${$target.text().trim()}`,
        category: eventCategory,
        custom: {
          // eslint-disable-next-line camelcase
          link_metadata: {
            // eslint-disable-next-line camelcase
            destination_url: destination,
          },
          metadata: {
            // eslint-disable-next-line camelcase
            target_url: href,
          },
          // eslint-disable-next-line camelcase
          event_metadata: {
            // eslint-disable-next-line camelcase
            download_title: eventCategory === DOWNLOAD_ACTION_CATEGORY ? $target.text().trim() : '',
          },
        },
      });
    });
  }
}

new TrackingEvent().init();
